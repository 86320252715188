import React, { Component } from 'react';
import nextArrow from '../../../assets/images/icons/long-arrow.svg';
import './styles.scss';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap'

export interface Props {
  btnText: string;
  iconActive?: boolean;
  href?: any;
  clickHandler?: () => void;
  className?: string;
  isLoading?: boolean
}

class ButtonPrimary extends Component<Props> {
  render() {
    const { btnText, iconActive, clickHandler, href, className, isLoading } = this.props;
    return (
      <Link
        onClick={(e) => {
          if (!href) {
            e.preventDefault();
          }

          if (clickHandler) {
            clickHandler();
          }
        }}
        className={`button-style ${className}`}
        to={href ? href : '#'}

      >
        {isLoading &&
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        }
        <span> {btnText}</span>
        {iconActive ? (
          <img
            src={nextArrow}
            alt=""
            width="25"
            className="btn-icon img-white"
          />
        ) : null}
      </Link>
    );
  }
}

export default ButtonPrimary;
