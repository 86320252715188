import React, { Component } from 'react';
import './styles.scss';

export interface Props {
  title?: string;
  imgUrl?: any;
}

interface StateType {}

class EmptyState extends Component<Props, StateType> {
  render() {
    const { imgUrl, title } = this.props;
    return (
      <div className="emptystate-container">
        <img src={imgUrl} alt="" className="img-icon" />
        <h4 className="title4">{title}</h4>
      </div>
    );
  }
}

export default EmptyState;
