export const isEmpty = (value: any) => {
  return value == '' || value == null;
};
export const isValidEmail = (value: any) => {
  return /^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(value);
};

export const isValidPassword = (string: string) => {
  if (string.length < 8) {
    return false;
  }
  const regex = new RegExp(
    /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
  );
  return regex.test(string);
};

export const isValidUsername = (string: string) =>
  /^[a-z\d_]{4,16}$/.test(string);

export const isPasswordSame = (password: string, confirmPassword: string) =>
  password === confirmPassword;
