import React, { Component } from 'react';
import logo from '../../assets/images/logo/devlogs.png';
import './styles.scss';

export interface Props {
  isLogoActive: boolean;
}

class LeftRoundedCard extends Component<Props> {
  render() {
    const { children, isLogoActive } = this.props;
    return (
      <div className="card-container">
        {isLogoActive ? (
          <div className="logo-box">
            <img src={logo} width="120" alt="" />
          </div>
        ) : null}

        {children}
      </div>
    );
  }
}

export default LeftRoundedCard;
