import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import './styles.scss';

export interface Props {
  children: any;
  className?: string;
}

class LabelPrimary extends Component<Props> {
  render() {
    const { children, className } = this.props;
    return (
      <div className={`label-container ${className}`}>
        <Form.Label className="label-primary">{children}</Form.Label>
      </div>
    );
  }
}

export default LabelPrimary;
