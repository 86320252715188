import React, { Component, useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import EmptyState from '../../../components/EmptyState';
import linkExpiredIcon from '../../../assets/images/icons/expired.svg';
import { useLocation } from 'react-router-dom';
import { verifyToken } from '../../../services/auth';

// Custom Styles
import './styles.scss';
import { toast } from 'react-toastify';

function LoginScreen() {
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const search = useLocation().search;
  useEffect(() => {
    (async () => {
      setLoading(true);
      const token: any = new URLSearchParams(search).get('token');
      if (token) {
        const response = await verifyToken(token);
        setLoading(false);
        if (response.code == 200) {
          history.push('/set-username-password');
        } else {
          toast.error(response.data.message);
        }
      } else {
        setLoading(false);
        toast.error('Invalid token');
      }
    })();
  }, []);

  return (
    <>
      {loading ? (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      ) : isLinkExpired ? (
        <EmptyState
          title="Verification token has been expired"
          imgUrl={linkExpiredIcon}
        />
      ) : (
        <div className="login-container">
          <h3 style={{ display: 'flex' }}>Welcome to DevLogs</h3>
        </div>
      )}
    </>
  );
}

export default withRouter<any, any>(LoginScreen);
