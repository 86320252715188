import { axiosInstance } from '../utilities/configureAxios';
import { routeConstants } from '../constants/apiRoutes';
import { SetUsernameAndPasswordType } from '../constants/types';
const { verifyTokenRoute, setUsernameAndPasswordRoute } = routeConstants;
const verifyToken = async (token: string) => {
  try {
    const response: any = await axiosInstance.post(verifyTokenRoute(token));
    axiosInstance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${response?.meta?.token}`;
    localStorage.setItem('token', response?.meta?.token);
    return response;
  } catch {
    throw new Error('API Failed');
  }
};

const setUsernameAndPassword = async (data: SetUsernameAndPasswordType) => {
  try {
    const response: any = await axiosInstance.post(
      setUsernameAndPasswordRoute(),
      data,
    );
    return response;
  } catch {
    throw new Error('API Failed');
  }
};
export { verifyToken, setUsernameAndPassword };
