import axios from 'axios';
import { getUrl } from '../utilities/helpers';
import { routeConstants } from '../constants/apiRoutes';
import { toast } from 'react-toastify';

const configureAxios = () =>
  axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 25000,
  });

export const axiosInstance = configureAxios();

axiosInstance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return await config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (err) => {
    if (!err.response) {
      //internet connection issue
      toast.error('No Internet connection');
    } else {
      if (err.response.status == 403) {
        toast.error('permission denied');
      }
      return err.response;
    }
    return Promise.reject(err);
  },
);
