import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

// Onboarding
import Login from './containers/onboarding/Login';
import Signup from './containers/onboarding/Signup';

const Routers: React.FC<{}> = () => {
  return (
    <BrowserRouter>
      {/* Onboarding Flow */}
      <Route exact path="/signup" component={Login} />
      <Route path="/set-username-password" component={Signup} />
    </BrowserRouter>
  );
};

export default Routers;
