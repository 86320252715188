import React from 'react';
import Router from './Router';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/App.scss';

function App() {
  return (
    <div className="main-wrapper-section">
      <Router />
    </div>
  );
}

export default App;
