import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import ButtonPrimary from '../../Buttons/ButtonPrimary';
import doneImage from '../../../assets/images/icons/check-tick.svg';

import './styles.scss';

export interface PropsType {
  closeModal: () => void;
  isModalVisible: boolean;
  className?: any;
  btnText?: any;
  href?: any;
  titleText?: string;
  onButtonClick?: () => void;
}

class SuccessModal extends Component<PropsType> {
  render() {
    const {
      closeModal,
      isModalVisible,
      className,
      btnText,
      href,
      titleText,
      onButtonClick,
    } = this.props;
    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={isModalVisible}
        onHide={closeModal}
        centered
        className={`success-modal primary-modal-style ${className}`}
      >
        <Modal.Header>
          <Modal.Title
            className="d-flex flex-grow-1 align-items-center justify-content-center"
            id="contained-modal-title-vcenter"
          >
            <div onClick={closeModal} className="modal-close">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="8"
                width="8"
                viewBox="0 0 365.696 365.696"
                style={{ fill: 'gray' }}
              >
                <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
              </svg>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={doneImage} width="70" alt="" />

          <h6 className="title6">{titleText}</h6>

          <div className="btn-section">
            <ButtonPrimary
              clickHandler={() => {
                closeModal();
                onButtonClick?.();
              }}
              btnText={btnText}
              href={href}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default SuccessModal;
