import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import './styles.scss';

export interface Props {
  value?: string | string[] | number;
  handleChange: any;
  errors: string;
  name: string;
  type: string;
  placeholder?: string;
  // label: string;
  isInvalid: boolean;
  className?: string;
  helperText?: string;
}

class InputPrimary extends Component<Props> {
  render() {
    const {
      value,
      handleChange,
      errors,
      isInvalid,
      name,
      placeholder,
      type,
      // label,
      className,
      helperText,
    } = this.props;
    return (
      <div className="input-container">
        {/* <Form.Label className="label-primary">{label}</Form.Label> */}
        <Form.Control
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={handleChange}
          isInvalid={isInvalid}
          className={`form-control ${className}`}
        />
        <Form.Text className="text-muted">{helperText}</Form.Text>

        <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
      </div>
    );
  }
}

export default InputPrimary;
