import React, { Component, useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import queryString from 'query-string';
import LeftRoundedCard from '../../../components/LeftRoundCard';
import InputPrimary from '../../../components/InputPrimary';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import LabelPrimary from '../../../components/LabelPrimary';
import SuccessModal from '../../../components/Modals/SuccessModal';
import { loginValidator } from '../../../validators/auth';
import { withRouter } from 'react-router-dom';
import { setUsernameAndPassword } from '../../../services/auth';

// images
import illustration1 from '../../../assets/images/illustration/password.svg';

import './styles.scss';
import { toast } from 'react-toastify';
import {
  isPasswordSame,
  isValidPassword,
  isValidUsername,
} from '../../../validators/global';

export interface StateType {
  isSuccessModalVisible: any;
  password: string;
  password_confirmation: string;
  loading: boolean;
  username: string;
}
interface PropsType {}

function SignupScreen() {
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [valid, setvalid] = useState(false);

  const handleSignup = async () => {
    setLoading(true);
    const response = await setUsernameAndPassword({
      password,
      confirmPassword,
      username,
    });
    setLoading(false);
    if (response.code == 200) {
      setIsSuccessModalVisible(true);
    } else {
      toast.error(response.data.message);
    }
  };

  const successToggleModal = () => {
    setIsSuccessModalVisible(!isSuccessModalVisible);
  };

  useEffect(() => {
    if (
      isPasswordSame(password, confirmPassword) &&
      isValidPassword(password) &&
      isValidPassword(confirmPassword) &&
      isValidUsername(username)
    ) {
      setvalid(true);
    } else {
      setvalid(false);
    }
  }, [username, password, confirmPassword]);

  return (
    <div className="signup-container">
      <Container fluid className="p--0">
        <Row>
          <Col sm={5}>
            <LeftRoundedCard isLogoActive={true}>
              <h1 className="title1">
                Let’s setup your <br /> username and password
              </h1>

              <p className="lead mb--30 mt--30">
                Enter your username and password for account activation.
              </p>
              <div className="form-section">
                <LabelPrimary>Enter your username</LabelPrimary>
                <InputPrimary
                  className={''}
                  value={username}
                  placeholder={'Enter your username'}
                  name={'Username'}
                  isInvalid={!isValidUsername(username)}
                  errors={'Username field is invalid'}
                  type="text"
                  handleChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                  ) => {
                    setUsername(event.target.value);
                  }}
                  helperText={
                    '* At least 4 characters; lowercase, numeric and _ allowed'
                  }
                />
              </div>

              <div className="form-section">
                <LabelPrimary>Create password</LabelPrimary>
                <InputPrimary
                  value={password}
                  placeholder={'Create password'}
                  name={'password'}
                  isInvalid={!isValidPassword(password)}
                  errors={'Password field is invalid'}
                  type="password"
                  handleChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                  ) => {
                    setPassword(event.target.value);
                  }}
                  helperText={
                    '* At least 8 characters, must include an uppercase, a lowercase and a numeric or special character'
                  }
                />
              </div>
              <div className="form-section position-relative">
                <LabelPrimary>Confirm new password</LabelPrimary>
                <InputPrimary
                  value={confirmPassword}
                  placeholder={'Confirm new password'}
                  name={'confirm password'}
                  isInvalid={
                    !(
                      isPasswordSame(password, confirmPassword) &&
                      isValidPassword(confirmPassword)
                    )
                  }
                  errors={'Confirm password field is invalid'}
                  type="password"
                  handleChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                  ) => {
                    setConfirmPassword(event.target.value);
                  }}
                />
              </div>

              <ButtonPrimary
                btnText={'Signup'}
                clickHandler={handleSignup}
                className={loading || !valid ? 'link-disabled ' : ''}
                isLoading={loading}
                iconActive={true}
              />
            </LeftRoundedCard>
          </Col>
          <Col sm={7}>
            <div className="pattern-bg pattern-rect-style">
              <div className="rect-center"></div>
              <div className="onboarding-content-section">
                <img src={illustration1} alt="" className="img-illustration1" />
                <h3 className="title3 mb--20">
                  Hope to meet you on the app 🤘
                </h3>
                <p className="lead text-lead-right">
                  DevLogs is where developers connect about development, from
                  architecture to tech stack, from team dynamics to culture.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <SuccessModal
        titleText="Your account is activated now! please continue to Login into App with your email and password."
        isModalVisible={isSuccessModalVisible}
        closeModal={successToggleModal}
        btnText="Go To The App "
        onButtonClick={() => {
          window.location.href = 'https://devlogs.page.link/app';
        }}
      />
    </div>
  );
}

export default withRouter<any, any>(SignupScreen);
